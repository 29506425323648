<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CLIENT LIST

type    : view

uses    : header-view
          main-container
          table-status

route   : /clients (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-client-list" v-if="clients">
    <header-view
      title="Clients"
      :btnOptions="[
        {
          name: 'New Client',
          action: () => {
            $router.push({name: 'ClientCreate'});
          },
        },
      ]" />
    <main-container>
      <div>
        <v-card>
          <v-card-title>
            <v-btn
              height="28"
              class="button-primary"
              @click="filterdialog = true">
              <span>Filter</span>
              <v-icon right small>mdi-filter</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredClients"
            class="elevation-1"
            :search="search"
            sort-by="number"
            :loading="busy">
            <template v-slot:item="props">
              <tr
                @click="
                  $router.push({
                    name: 'ClientView',
                    params: {uuid: props.item.uuid},
                  })
                ">
                <td>
                  CLIENT-{{ props.item.number.toString().padStart(5, '0') }}
                </td>
                <td>{{ props.item.client_name }}</td>
                <td>{{ props.item.contact_name }}</td>
                <td>{{ props.item.contact_phone }}</td>
                <td class="text-center">
                  <table-status :status="props.item.client_status" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <header-view title="Filter Clients" dialog />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Client Name Contains"
                    v-model="client_name"
                    hint=""
                    required />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Contact Name Contains"
                    v-model="contact_name"
                    hint=""
                    required />
                </v-flex>

                <v-flex xs12>
                  <div id="client-status-filter">
                    <v-select
                      v-model="client_status"
                      :items="filterItems"
                      filled
                      label="Status" />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button-primary" flat @click="applyFilter()"
              >Apply</v-btn
            >
            <v-btn class="button-secondary" flat @click="clearFilter()"
              >Clear</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // components
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';

  // services
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppClientList',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
      'table-status': TableStatus,
    },
    data() {
      return {
        filterdialog: false,
        client_name: '',
        contact_name: '',
        client_status: '',
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'Client ID',
            align: 'left',
            sortable: true,
            value: 'number',
          },
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'client_name',
          },
          {
            text: 'Contact Name',
            value: 'contact_name',
          },
          {
            text: 'Contact Phone',
            value: 'contact_phone',
          },
          {
            text: 'Status',
            value: 'client_status',
            align: 'center',
          },
        ],

        clients: null,
        allowView: false,
        allowEdit: false,
        allowCreate: false,
      };
    },
    computed: {
      // a computed getter
      filteredClients() {
        // `this` points to the vm instance
        return this.clients.filter(
          function (client) {
            let pass = true;
            if (
              this.client_name &&
              !client.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }

            if (
              this.contact_name &&
              !client.contact_name
                .toLowerCase()
                .includes(this.contact_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.client_status &&
              client.client_status != this.client_status
            ) {
              pass = false;
            }
            return pass;
          }.bind(this)
        );
      },
    },
    async mounted() {
      this.busy = true;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // Get list of clients for tenant
      var res = await Clients.getClients(
        {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
        },
        accessToken
      );

      if (res) {
        this.clients = res;
        this.busy = false;
      } else {
        this.busy = false;
        this.errorMessage = 'API call failed';
      }
    },
    created() {
      this.getAllowed();
    },
    methods: {
      async getAllowed() {
        this.allowTenants = this.$auth.isAllowed('clients', 'list');
        this.allowView = this.$auth.isAllowed('clients', 'view');
        this.allowEdit = this.$auth.isAllowed('clients', 'edit');
        this.allowCreate = this.$auth.isAllowed('clients', 'create');
      },
      clearFilter: function () {
        this.client_name = '';
        this.contact_name = '';
        this.client_status = '';
        this.filterdialog = false;
      },
      applyFilter: function () {
        this.filterdialog = false;
        this.filteredClients();
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
