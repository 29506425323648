var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clients)?_c('div',{staticClass:"app-client-list"},[_c('header-view',{attrs:{"title":"Clients","btnOptions":[
      {
        name: 'New Client',
        action: function () {
          _vm.$router.push({name: 'ClientCreate'});
        },
      } ]}}),_c('main-container',[_c('div',[_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"button-primary",attrs:{"height":"28"},on:{"click":function($event){_vm.filterdialog = true}}},[_c('span',[_vm._v("Filter")]),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"QuickSearch","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredClients,"search":_vm.search,"sort-by":"number","loading":_vm.busy},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.$router.push({
                  name: 'ClientView',
                  params: {uuid: props.item.uuid},
                })}}},[_c('td',[_vm._v(" CLIENT-"+_vm._s(props.item.number.toString().padStart(5, '0'))+" ")]),_c('td',[_vm._v(_vm._s(props.item.client_name))]),_c('td',[_vm._v(_vm._s(props.item.contact_name))]),_c('td',[_vm._v(_vm._s(props.item.contact_phone))]),_c('td',{staticClass:"text-center"},[_c('table-status',{attrs:{"status":props.item.client_status}})],1)])]}}],null,false,2131463966)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.filterdialog),callback:function ($$v) {_vm.filterdialog=$$v},expression:"filterdialog"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('header-view',{attrs:{"title":"Filter Clients","dialog":""}}),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Client Name Contains","hint":"","required":""},model:{value:(_vm.client_name),callback:function ($$v) {_vm.client_name=$$v},expression:"client_name"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Contact Name Contains","hint":"","required":""},model:{value:(_vm.contact_name),callback:function ($$v) {_vm.contact_name=$$v},expression:"contact_name"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{attrs:{"id":"client-status-filter"}},[_c('v-select',{attrs:{"items":_vm.filterItems,"filled":"","label":"Status"},model:{value:(_vm.client_status),callback:function ($$v) {_vm.client_status=$$v},expression:"client_status"}})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"button-primary",attrs:{"flat":""},on:{"click":function($event){return _vm.applyFilter()}}},[_vm._v("Apply")]),_c('v-btn',{staticClass:"button-secondary",attrs:{"flat":""},on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v("Clear")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }